/*
Template Name: Appvilla - Creative Landing Page HTML Template.
Author: GrayGrids
*/

(function () {
  //===== Prealoder

  window.onload = function () {
    window.setTimeout(fadeout, 500);
  };

  function fadeout() {
    document.querySelector(".preloader").style.opacity = "0";
    document.querySelector(".preloader").style.display = "none";
  }

  //   /*=====================================
  //   Sticky
  //   ======================================= */
  //   window.onscroll = function () {
  //       var header_navbar = document.querySelector(".navbar-area");
  //       var sticky = header_navbar.offsetTop;

  //       var logo = document.querySelector('.navbar-brand img')
  //       if (window.pageYOffset > sticky) {
  //         header_navbar.classList.add("sticky");
  //         logo.src = 'assets/images/logo/logo.svg';
  //       } else {
  //         header_navbar.classList.remove("sticky");
  //         logo.src = 'assets/images/logo/white-logo.svg';
  //       }

  //       // show or hide the back-top-top button
  //       var backToTo = document.querySelector(".scroll-top");
  //       if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
  //           backToTo.style.display = "flex";
  //       } else {
  //           backToTo.style.display = "none";
  //       }
  //   };

  //   // section menu active
  // function onScroll(event) {
  // 	var sections = document.querySelectorAll('.page-scroll');
  // 	var scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

  // 	for (var i = 0; i < sections.length; i++) {
  // 		var currLink = sections[i];
  // 		var val = currLink.getAttribute('href');
  // 		var refElement = document.querySelector(val);
  // 		var scrollTopMinus = scrollPos + 73;
  // 		if (refElement.offsetTop <= scrollTopMinus && (refElement.offsetTop + refElement.offsetHeight > scrollTopMinus)) {
  // 			document.querySelector('.page-scroll').classList.remove('active');
  // 			currLink.classList.add('active');
  // 		} else {
  // 			currLink.classList.remove('active');
  // 		}
  // 	}
  // };

  // window.document.addEventListener('scroll', onScroll);

  // for menu scroll
  var pageLink = document.querySelectorAll(".page-scroll");

  pageLink.forEach((elem) => {
    elem.addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector(elem.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
        offsetTop: 1 - 60,
      });
    });
  });

  // WOW active
  // new WOW().init();

  // //===== mobile-menu-btn
  // let navbarToggler = document.querySelector(".mobile-menu-btn");
  // navbarToggler.addEventListener('click', function () {
  //     navbarToggler.classList.toggle("active");
  // });
})();
