import { List, Datagrid, TextField, DateField, EditButton, BooleanField } from "react-admin";

export const PoolList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="ownerId" />
            <TextField source="status" />
            <TextField source="code" />
            <DateField source="poolStartDate" />
            <DateField source="poolEndDate" />
            <TextField source="poolType" />
            <BooleanField source="communityPool" />
            <EditButton />
        </Datagrid>
    </List>
);
