import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Snackbar, Alert, CircularProgress } from '@mui/material';
import { Form, Field } from 'react-final-form';
import { auth0 } from './AdminPanel';

// Define a type for the form values
type NotificationFormValues = {
  title: string;
  subtext: string;
};

export default function Notification() {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSendToMyself = async (values: NotificationFormValues, form: any) => {
    setLoading(true);
    const token = await auth0.getTokenSilently();
    fetch(process.env.REACT_APP_API_URL + '/admin/notification/myself', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(values),
    })
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .then(data => {
        form.reset(); // Clear the form
        setSuccessMessage("Notification sent successfully!");
      })
      .catch(error => {
        console.error("Error sending notification:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendToEveryone = () => {
    setConfirmOpen(true);
  };

  const handleConfirm = async (values: NotificationFormValues, form: any) => {
    setConfirmOpen(false);
    setLoading(true);
    const token = await auth0.getTokenSilently();
    fetch(process.env.REACT_APP_API_URL + '/admin/notification/everyone', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(values),
    })
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .then(data => {
        form.reset(); // Clear the form
        setSuccessMessage("Notification sent successfully!");
      })
      .catch(error => {
        console.error("Error sending notification:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Form<NotificationFormValues>  // Specify form values type
        onSubmit={() => {}} // Empty because buttons handle submission
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Field name="title">
                {({ input }) => (
                  <TextField
                    {...input}
                    label="Title"
                    fullWidth
                    margin="normal"
                  />
                )}
              </Field>
            </div>
            <div>
              <Field name="subtext">
                {({ input }) => (
                  <TextField
                    {...input}
                    label="Subtext"
                    fullWidth
                    margin="normal"
                  />
                )}
              </Field>
            </div>
            <div style={{ marginTop: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSendToMyself(values as NotificationFormValues, form)}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Send to Myself"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleSendToEveryone()}
                style={{ marginLeft: '8px' }}
              >
                Send to Everyone
              </Button>
            </div>

            <Dialog
              open={confirmOpen}
              onClose={() => setConfirmOpen(false)}
            >
              <DialogTitle>Confirm Send to Everyone</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to send this notification to everyone?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmOpen(false)} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => handleConfirm(values as NotificationFormValues, form)} color="secondary">
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      />

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
      >
        <Alert onClose={() => setSuccessMessage(null)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
