import { Edit, SimpleForm, BooleanInput, TextInput } from 'react-admin';

export const PoolEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput label="Name" source="name" />
      <BooleanInput label="Community Pool" source="communityPool" />
    </SimpleForm>
  </Edit>
);
