import { List, Datagrid, TextField, DateField, EditButton, BooleanField } from "react-admin";

export const FightFighterList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="fightId" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="nickname" />
            <TextField source="fightStatus" />
            <TextField source="moneyLine" />
            <EditButton />
        </Datagrid>
    </List>
);
