import type { ReactNode } from "react";
import { Layout as RALayout, CheckForApplicationUpdate, Menu } from "react-admin";
import NotificationIcon from '@mui/icons-material/Notifications';

export const MyMenu = () => (
    <Menu>
        <Menu.ResourceItems />
        <Menu.Item to="/admin/notification" primaryText="Notification" leftIcon={<NotificationIcon />}/>
    </Menu>
);

export const Layout = ({ children }: { children: ReactNode }) => (
  <RALayout menu={MyMenu}>
    {children}
    <CheckForApplicationUpdate />
  </RALayout>
);
