import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.scss";
import "./assets/js/bootstrap.min.js";
import "./assets/js/count-up.min.js";
import "./assets/js/glightbox.min.js";
import "./assets/js/tiny-slider.js";
import "./assets/js/wow.min.js";
import "./assets/js/main.js";


import App from "./App";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
