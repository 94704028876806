import { Admin, CreateParams, DeleteParams, DeleteManyParams, GetListParams, GetManyParams, GetManyReferenceParams, GetOneParams, QueryFunctionContext, Resource, UpdateParams, UpdateManyParams, CustomRoutes } from 'react-admin';
import { httpClient, Auth0AuthProvider } from 'ra-auth-auth0';
import jsonServerProvider from "ra-data-json-server";
import { Layout } from './Layout';
import { PoolList } from './pools';
import { Auth0Client } from '@auth0/auth0-spa-js';
// import Logout from './Logout';
import NoMansLand from './NoMansLand';
import { PoolEdit } from './poolsEdit';
import { Route } from 'react-router-dom';
import Notification from './Notification';
import { FightFighterList } from './fightFighters';
import { FightFighterEdit } from './fightFighterEdit';
// import styles from "./AdminPanel.module.scss";

export const auth0 = new Auth0Client({
  domain: 'mmafantasy.us.auth0.com',
  clientId: 'fDJCveAQSrVpH3a3ZFukuyzrbMjXtGSR',
  cacheLocation: 'localstorage',
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: "https://api.mmafantasy.live",
    namespace: "https://www.mmafantasy.live",
    scope: "openid profile offline_access"
  },
});

const authProvider = Auth0AuthProvider(auth0, {
  loginRedirectUri: `${window.location.origin}/admin/auth-callback`
});

// Initialize base jsonServerProvider with a different HTTP client for auth
const baseDataProvider = jsonServerProvider(process.env.REACT_APP_API_URL, httpClient(auth0));

const customDataProvider = {
    getList: (resource: string, params: GetListParams & QueryFunctionContext) => {
        if (resource === 'pools') {
          resource = 'admin/pools';
        } else if (resource === 'fightFighter') {
          resource = 'admin/fight-fighters';
        }
        return baseDataProvider.getList(resource, params);
    },
    getOne: (resource: string, params: GetOneParams<any> & QueryFunctionContext) => {
        if (resource === 'pools') {
          resource = 'admin/pools';
        } else if (resource === 'fightFighter') {
          resource = 'admin/fight-fighters';
        }
        return baseDataProvider.getOne(resource, params);
    },
    getMany: (resource: string, params: GetManyParams<any> & QueryFunctionContext) => {
        if (resource === 'pools') {
          resource = 'admin/pools';
        } else if (resource === 'fightFighter') {
          resource = 'admin/fight-fighters';
        }
        return baseDataProvider.getMany(resource, params);
    },
    getManyReference: (resource: string, params: GetManyReferenceParams & QueryFunctionContext) => {
        if (resource === 'pools') {
          resource = 'admin/pools';
        } else if (resource === 'fightFighter') {
          resource = 'admin/fight-fighters';
        }
        return baseDataProvider.getManyReference(resource, params);
    },
    create: (resource: string, params: CreateParams<any>) => {
        if (resource === 'pools') {
          resource = 'admin/pools';
        } else if (resource === 'fightFighter') {
          resource = 'admin/fight-fighters';
        }
        return baseDataProvider.create(resource, params);
    },
    update: (resource: string, params: UpdateParams<any>) => {
        if (resource === 'pools') {
          resource = 'admin/pools';
        } else if (resource === 'fightFighter') {
          resource = 'admin/fight-fighters';
        }
        return baseDataProvider.update(resource, params);
    },
    updateMany: (resource: string, params: UpdateManyParams<any>) => {
        if (resource === 'pools') {
          resource = 'admin/pools';
        } else if (resource === 'fightFighter') {
          resource = 'admin/fight-fighters';
        }
        return baseDataProvider.updateMany(resource, params);
    },
    delete: (resource: string, params: DeleteParams<any>) => {
        if (resource === 'pools') {
          resource = 'admin/pools';
        } else if (resource === 'fightFighter') {
          resource = 'admin/fight-fighters';
        }
        return baseDataProvider.delete(resource, params);
    },
    deleteMany: (resource: string, params: DeleteManyParams<any>) => {
        if (resource === 'pools') {
          resource = 'admin/pools';
        } else if (resource === 'fightFighter') {
          resource = 'admin/fight-fighters';
        }
        return baseDataProvider.deleteMany(resource, params);
    },
};

export default function AdminPanel() {
  if (!auth0) {
    return <div>Loading...</div>;
  }

  return (
    <Admin
      basename="/admin"
      layout={Layout}
      authProvider={authProvider}
      dataProvider={customDataProvider}
    >
      {
        permissions => (
          permissions && permissions.includes('Admin') ? (
            <>
              <Resource name="pools" list={PoolList} edit={PoolEdit} />
              <Resource name="fightFighter" list={FightFighterList} edit={FightFighterEdit} />
              <CustomRoutes>
                <Route path="/notification" element={<Notification />} />
              </CustomRoutes>
            </>
          ) : (
            <>
              <Resource name="*" list={NoMansLand} />
            </>
          )
        )
      }
    </Admin>
  );
}
