import Header from "./Header";
import appleSvg from "./assets/images/download-apple.svg";
import homescreenHero from "./assets/images/hero-image.png"

export default function Home() {
  return (
    <>
      <Header />
      <section id="home" className="hero-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="hero-content">
                <h1 className="wow fadeInLeft" data-wow-delay=".4s">Create your own fantasy sports MMA pool
                </h1>
                <p className="wow fadeInLeft" data-wow-delay=".6s">Make your fight picks for the UFC fights.
                  Track who gets the most points from predicting the result of UFC fights using a leaderboard.</p>
                <div className="button wow fadeInLeft" data-wow-delay=".8s">
                  <a target="_blank" href="https://apps.apple.com/ca/app/mma-fantasy/id6445804757" rel="noreferrer">
                    <img src={appleSvg} alt="Download MMA Fantasy on Apple App Store" />
                  </a>

                  <a href='https://play.google.com/store/apps/details?id=com.jetset15.MMAFantasy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                    target="_blank" rel="noreferrer">
                    <img alt='Download MMA Fantasy on Google Play' width="150"
                      src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="wow hero-image fadeInRight text-center" data-wow-delay=".4s">
                <img src={homescreenHero} alt="Make your fight picks for the UFC fights on our MMA App." />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">Features</h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">Your Experience Gets Better And Better Over Time.
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">Dive into the ultimate MMA Fantasy experience with our mobile
                  app. Engage with real-time updates, customizable pools,
                  and a vibrant community.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                <i className="lni lni-cloud-upload"></i>
                <h3>Pool Management</h3>
                <p>Create personalized UFC fantasy pools with ease. Manage scoring rules, scheduled events included and users.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                <i className="lni lni-star"></i>
                <h3>Leaderboards</h3>
                <p>Track your standing in pools with real-time leaderboards. Compete and climb ranks through strategic play.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                <i className="lni lni-reload"></i>
                <h3>Social Sharing</h3>
                <p>Generate and share custom fight pick images on social media. Increase visibility and engage friends.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                <i className="lni lni-shield"></i>
                <h3>Community Pools</h3>
                <p>Join or view a variety of community pools. Enhance your experience by engaging with the MMA community.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                <i className="lni lni-cog"></i>
                <h3>Performance Insights</h3>
                <p>Access fighter histories for informed decision-making. Customize notifications for updates and insights.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                <i className="lni lni-layers"></i>
                <h3>Gameplay Flexibility</h3>
                <p>Customize picks with fighter, method, and round choices. Change selections anytime before the event.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
