import { Edit, SimpleForm, TextInput, TextField } from 'react-admin';

export const FightFighterEdit = () => (
  <Edit>
    <SimpleForm>
      <TextField source="id" />
      <TextField source="fightId" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="nickname" />
      <TextField source="fightStatus" />
      <TextInput label="MoneyLine" source="moneyLine" />
    </SimpleForm>
  </Edit>
);
