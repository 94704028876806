import styles from "./Comparison.module.scss";
import Header from "./Header";
import appleSvg from "./assets/images/download-apple.svg";

export default function Comparison() {
  return (
    <>
      <Header />
      <div className={`container ${styles.comparison}`}>
        <h1 className="mb-5">Verdict vs MMA Fantasy</h1>

        <h4>Feature Set and Innovation:</h4>
        <p>
          <strong>MMA Fantasy:</strong> Immerse yourself in the most comprehensive feature set tailored to enhance your MMA
          Fantasy adventure.
          Experience customizable pools, live scoring updates, and full card fight predictions.
        </p>
        <br />
        <p>
          <strong>Verdict:</strong> Although Verdict provides a solid foundation, its feature set lacks the depth and
          innovation found in MMA
          Fantasy. Users may find limited customization options and can only make picks for the main card.
        </p>
        <br />

        <h4>User Experience and Interface</h4>
        <p><strong>MMA Fantasy:</strong> Navigate seamlessly with our sleek and intuitive interface, facilitating effortless
          creation and
          management
          of your fantasy pools. Enjoy a visually stunning experience that elevates every facet of your MMA Fantasy journey.
        </p>
        <br />
        <p>
          <strong>Verdict:</strong> While functional, Verdict's interface lacks the polish and user-friendly design of MMA
          Fantasy. Users may
          experience clunky navigation, impacting overall user satisfaction.
        </p>

        <br />

        <h4>Community Engagement and Interaction</h4>
        <p><strong>MMA Fantasy:</strong> Join a vibrant community of MMA enthusiasts, engaging in dynamic discussions, sharing
          insights, and
          competing against fellow fans. Our platform fosters camaraderie and excitement, enhancing the overall fantasy
          experience.
        </p>
        <br />
        <p>
          <strong>Verdict:</strong> While Verdict offers a community feature, it may not match the level of engagement and
          interaction found on
          MMA
          Fantasy. Our active community ensures every fan feels connected and supported throughout their fantasy journey.
        </p>

        <br />

        <h4>Customer Support and Satisfaction</h4>
        <p><strong>MMA Fantasy:</strong> Count on our dedicated support team for prompt and personalized assistance, ensuring
          a smooth and
          enjoyable
          experience. Whether you have questions about league settings or encounter technical issues, we're here to help.
        </p>
        <br />
        <p>
          <strong>Verdict:</strong> While Verdict offers customer support, it may not prioritize user satisfaction to the same
          extent as MMA
          Fantasy. Opt for us for peace of mind, knowing your needs are our top priority.
        </p>

        <br />

        <h4>Download MMA Fantasy on iOS or Android</h4>
        <p>
          Elevate your fantasy MMA experience with MMA Fantasy, your ultimate destination for cutting-edge features,
          user-friendly
          design, vibrant community engagement, and top-notch customer support. Join us today and embark on the ultimate MMA
          Fantasy journey!
        </p>
        <br />
        <a target="_blank" href="https://apps.apple.com/ca/app/mma-fantasy/id6445804757" rel="noreferrer">
          <img src={appleSvg} alt="Download MMA Fantasy on Apple App Store" />
        </a>

        <a href='https://play.google.com/store/apps/details?id=com.jetset15.MMAFantasy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
          target="_blank" rel="noreferrer">
          <img alt='Download MMA Fantasy on Google Play' width="150"
            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
      </div>
    </>
  );
}
