
import discord from './assets/images/discord.png';
import Header from './Header';
import "./Help.scss";

export default function Help() {
  return (
    <>
      <Header />
      <section className="faq section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">Faq</h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">Frequently Asked Questions</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                      aria-expanded="true" aria-controls="collapseOne">
                      <span className="title"><span className="serial">01</span>How do I contact customer
                        support?</span><i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>You can email us <a href="mailto:mmafantasylive@gmail.com">mmafantasylive@gmail.com</a> or<a
                          className="p-2 social-media" target="_blank" href="https://discord.gg/BayzadAEzU" rel="noreferrer">
                          Join our discord for support <img src={discord} alt="Join our Discord" />
                        </a></p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span className="title"><span className="serial">02</span> How does scoring work?</span><i
                        className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>
                        +1 point for picking the winning fighter.<br />
                        +1 point for a correct extreme underdog pick.<br />
                        <strong>For the entire Main Card OR just Main/Co-Main fights (depending on your pool
                          settings)</strong><br />
                        +1 point for picking the correct method. (KO/Submisson/Decision)<br />
                        +1 point for picking the correct round.<br />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span className="title"><span className="serial">03</span>What is an extreme underdog?</span><i
                        className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>
                        An extreme underdog is a fighter whose american odds to win the fight have a difference of at least
                        500.
                        IE. Given the fight: Bo Nickal (-1998) vs Jamie Pickett (+902). Jamie Pickett is an extreme underdog
                        since the american odd point difference is greater than 500, it's 1096.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <span className="title"><span className="serial">04</span>Why can't I see the next event?</span><i
                        className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>Your pool must have an end date set in the future after the next event in order to see it.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <span className="title"><span className="serial">05</span>Do old results from previous fight pools remain
                        accessible?</span><i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>Yes, old results from fight pools stay in the "completed" tab.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      <span className="title"><span className="serial">06</span>Can I create multiple pools at the same time for
                        different
                        events?</span><i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>Yes, you can create as many pools as you want for different events simultaneously without issues.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSeven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      <span className="title"><span className="serial">07</span>Is there a limit to the number of members that can
                        join a
                        pool?</span><i className="lni lni-plus"></i>
                    </button>
                  </h2>
                  <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>There is no maximum limit to the number of members that can join a pool.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
