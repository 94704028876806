export default function Chatbot() {
  async function runCreatorIOChatbot() {
    const chatbotId = "be855e19-ae5b-4f74-8980-6e934666d744";

    var chatbotSettings: any = {};
    try {
      var response = await fetch(`https://app.creator.io/api/chatbot/settings?id=${chatbotId}`);
      chatbotSettings = await response.json();
    } catch (e) {
      console.log("Chatbot widget err", e);
    }

    const iframe = document.createElement('iframe');
    iframe.src = "https://app.creator.io/embed-content?chatbotId=" + chatbotId + "&host=" + window.location.hostname;
    iframe.id = "cioChatbotIframe";
    document.body.appendChild(iframe);

    const button = document.createElement('div');
    button.id = "cioChatbotToggleButton";
    document.body.appendChild(button);

    const chatSvg = `<svg width="24" height="22" viewBox="0 0 24 22" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.26887 5.54541 0 12 0C18.4546 0 24 4.26887 24 10C24 12.3521 22.9998 14.4625 21.4473 16.1523L21.9934 20.8854C22.0339 21.2363 21.8862 21.5826 21.6049 21.7963C21.3236 22.01 20.9504 22.0594 20.6232 21.9263L15.0396 19.655C14.0363 19.9152 13.0416 20 12 20C5.54541 20 0 15.7311 0 10ZM12 2C6.25459 2 2 5.73113 2 10C2 14.2689 6.25459 18 12 18C13.0383 18 13.9347 17.9056 14.8127 17.6422C15.0312 17.5766 15.2655 17.5877 15.4768 17.6737L19.8131 19.4376L19.4066 15.9146C19.3716 15.6114 19.477 15.3087 19.6929 15.0929C21.1478 13.638 22 11.8802 22 10C22 5.73113 17.7454 2 12 2Z"/>
              </svg>`;
    const closeSvg = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.7071 1.70711C16.0976 1.31658 16.0976 0.683418 15.7071 0.292893C15.3166 -0.0976311 14.6834 -0.0976311 14.2929 0.292893L8 6.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L6.58579 8L0.292893 14.2929C-0.0976311 14.6834 -0.0976311 15.3166 0.292893 15.7071C0.683417 16.0976 1.31658 16.0976 1.70711 15.7071L8 9.41421L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.41421 8L15.7071 1.70711Z" fill="white"/>
              </svg>`;
    let isIframeVisible = false;
    button.innerHTML = chatSvg;

    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    const css = `
      #cioChatbotIframe {
        border: none;
        position: fixed;
        width: 430px;
        height: 625px;
        z-index: 99999997;
        overflow: hidden;
        right: -1000px;
        bottom: -1000px;
      }
      #cioChatbotToggleButton {
        cursor: pointer;
        position: fixed;
        bottom: 20px;
        right: 25px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background-color: ${chatbotSettings?.primary_color || '#040506'};
        color: #fff;
        z-index: 99999998;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        display: none;
      }
      @media screen and (max-width: 767px) {
        #cioChatbotIframe {
          width: 100%!important;
          height: 100%!important;
          right: 0!important;
          bottom: 0!important;
          z-index: 99999999!important;
        }
      }
  `;
    styleElement.appendChild(document.createTextNode(css));
    document.head.appendChild(styleElement);

    function resizeBotIframe() {
      const windowSize = { width: window.innerWidth, height: window.innerHeight };
      if (iframe?.contentWindow)
        iframe.contentWindow.postMessage(windowSize, 'https://app.creator.io');
    }

    button.addEventListener('click', function() {
      if (isIframeVisible) {
        iframe.style.display = 'none';
        button.innerHTML = chatSvg;
      } else {
        iframe.style.display = 'block';
        button.innerHTML = closeSvg;
        if(iframe && iframe?.contentWindow) {
          iframe.contentWindow.postMessage('openChatbot', 'https://app.creator.io');
        }
        resizeBotIframe();
      }
      isIframeVisible = !isIframeVisible;
    });

    window.addEventListener('message', function(event) {
      if (event.data === 'closeChatbot') {
        iframe.style.display = 'none';
        isIframeVisible = false;
        button.innerHTML = chatSvg;
      }
    });

    iframe.addEventListener('load', () => {
      iframe.style.display = 'none';
      iframe.style.bottom = '60px';
      iframe.style.right = '13px';
      resizeBotIframe();
      button.style.display = "flex";
    });
    window.addEventListener('resize', () => {
      resizeBotIframe();
    });
  }

  function updateViewportMetadata() {
    var viewportMeta: any = document.querySelector('meta[name="viewport"]');
    var newViewportContent = 'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0';

    if (viewportMeta && viewportMeta.content) {
      viewportMeta.content = newViewportContent;
    } else {
      var newViewportMeta = document.createElement('meta');
      newViewportMeta.name = 'viewport';
      newViewportMeta.content = newViewportContent;
      document.head.appendChild(newViewportMeta);
    }
  }

  function runCIOChatbot() {
    try {
      runCreatorIOChatbot();
      setTimeout(updateViewportMetadata, 1000);
    } catch (e) {
      console.log("[creator.io] chatbot error", e);
    }
  }


  runCIOChatbot();

  return (
    <>
    </>
  )
}
