import Header from "./Header";
import styles from "./Terms.module.scss";

export default function Terms() {
  return (
    <>
      <Header />
      <div className={`${styles.terms} container`}>
        <h1 className="mb-5">Terms of Service for MMA Fantasy App</h1>

        <h4>Effective Date: February 22, 2023</h4>

        <p>Welcome to the MMA Fantasy app (the "Service") operated by MMA Fantasy ("us", "we", or "our"). By accessing or
          using the Service, you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms,
          you
          may not use the Service.</p>
        <br />

        <h4>Accounts</h4>
        <p>To use the Service, you must create an account and provide accurate, complete, and current information. You must be
          at
          least 18 years old to create an account. You are responsible for maintaining the confidentiality of your account
          information and password. You agree to accept responsibility for all activities that occur under your account. You
          must
          notify us immediately of any unauthorized use of your account or any other breach of security.</p>
        <br />

        <h4>Intellectual Property</h4>
        <p>The Service and its original content, features, and functionality are and will remain the exclusive property of
          MMA Fantasy and its licensors. The Service is protected by copyright, trademark, and other laws of both Canada
          and foreign countries. You may not reproduce, modify, or distribute any part of the Service without our prior
          written consent.</p>

        <br />

        <h4>Termination</h4>
        <p>We reserve the right to terminate or suspend your account and/or access to the Service immediately, without prior
          notice
          or liability, for any reason whatsoever, including without limitation, a breach of these Terms. All provisions of
          these
          Terms that by their nature should survive termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

        <br />

        <h4>Indemnification</h4>
        <p>You agree to defend, indemnify, and hold harmless MMA Fantasy, its affiliates, licensors, and service
          providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers,
          successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs,
          expenses,
          or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your
          use
          of the Service.</p>

        <br />

        <h4>Limitation of Liability</h4>
        <p>In no event shall MMA Fantasy, its affiliates, licensors, or service providers, or its or their respective
          officers, directors, employees, contractors, agents, licensors, suppliers, successors, or assigns, be liable for any
          indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits,
          data,
          use, goodwill, or other intangible losses, resulting from your access to or use of, or inability to access or use,
          the
          Service, whether based on warranty, contract, tort (including negligence), statute, or any other legal theory,
          whether
          or not we have been informed of the possibility of such damage.</p>

        <br />

        <h4>Prohibited Conduct</h4>
        <p>
          You agree not to:
          (a) use the App for any illegal purpose or in violation of any applicable law or regulation;
          (b) post or transmit any material that violates or infringes the rights of others, including without limitation,
          intellectual property rights;
          (c) interfere with or disrupt the App, its servers, or networks connected to the App;
          (d) attempt to gain unauthorized access to any portion of the App or any other accounts, computer systems, or
          networks
          connected to the App, whether through hacking, password mining, or any other means;
          (e) impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or
          entity;
          (f) use any robot, spider, or other automatic device, process, or means to access the App for any purpose, including
          monitoring or copying any of the material on the App;
          (g) introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically
          harmful;
          (h) engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the App.
        </p>

        <br />

        <h1 className="mt-5" id="privacy-policy">Privacy Policy for MMA Fantasy App</h1>

        <br />
        <p>
          We at MMA Fantasy respect your privacy and are committed to protecting it through our compliance with this Privacy
          Policy
          ("Policy"). This Policy describes the types of information we may collect from you or that you may provide when you
          use
          our MMA Fantasy IOS/Android app ("App") and our practices for collecting, using, maintaining, protecting, and
          disclosing
          that
          information.
        </p>

        <br />

        <h4>Information We Collect</h4>
        <p>
          We may collect several types of information from and about users of our App, including:
          (a) Information you provide to us directly when you create an account, such as your name, email address, and
          password;
          (b) Information about your use of the App, including statistics about the fights and picks you participate in;
        </p>

        <br />

        <h4>Use of Information</h4>
        <p>
          We may use the information we collect to:
          (a) Provide and improve the App and its features and functionality;
          (b) Respond to your inquiries and provide customer support;
          (c) Communicate with you about updates, new features, and other news related to the App;
          (d) Personalize your experience with the App;
          (e) Analyze and improve the effectiveness of our marketing and advertising campaigns;
          (f) Comply with applicable laws and regulations.
        </p>
        <br />

        <h4>Sharing of Information</h4>
        <p>
          We will never share your information with third-parties however we may share your information with:
          (a) Law enforcement or other government officials if required to do so by law, court order, or other legal process.
        </p>

        <br />

        <h4>Data Security</h4>
        <p>
          We have implemented measures designed to secure your personal information from accidental loss and from unauthorized
          access, use, alteration, and disclosure. However, no data transmission over the internet or any wireless network can
          be
          guaranteed to be 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee
          its
          absolute security.
        </p>

        <br />

        <h4>Changes to This Policy</h4>
        <p>
          We may update this Policy from time to time. If we make material changes to how we treat our users' personal
          information, we will notify you by email to the email address specified in your account and/or through a notice on
          the
          App.
        </p>

        <br />

        <h4>Contact Us</h4>
        <p>
          If you have any questions or concerns about our Privacy Policy, please contact us at <a
            href="mailto:mmafantasylive@gmail.com">mmafantasylive@gmail.com</a>.
        </p>
      </div>
    </>
  );
}
